.headerCont {
  height: 5rem;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.189);
  display: flex;
  align-items: center;
}

.dashboard {
  flex: 1;
  display: flex;
  align-items: center;
}
.dashboard > .logo {
  margin-left: 1rem;
  display: flex;
  align-items: center;
}
.dashboard > .logo > span {
  font-size: 1.2rem;
  font-weight: 700;
  color: #78472b;
}
.dashboard .logo img {
  width: 4rem;
}
.dashboard > div {
  margin-right: 1rem;
}

/* .add-hostel {
  padding: 2px 2px;
  cursor: pointer;
}

.add-hostel a {
  transition: all 0.2s ease;
}

.add-hostel a:hover {
  color: #78472b;
} */

.login-signup-button {
  margin-right: 1.5rem;
}
.login-signup-button button {
  border: none;
  outline: none;
  background-color: #78472b;
  padding: 0.6rem 0.5rem;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
  font-weight: 500;
}
.btn-icon {
  margin-right: 8px;
}
.login-signup-button button:hover {
  background-color: #218838;
}
a {
  text-decoration: none;
  color: inherit;
}

.header-profile > img {
  width: 60px;
  cursor: pointer;
}

.header-profile > div {
  position: absolute;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: 200px;
  padding: 1rem 0;
  z-index: 100;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.361);
}
.header-profile > div > div {
  transition: all 0.4s;
  cursor: pointer;
  border-radius: 4px;
  padding: 1rem;
}

.header-profile > div > div:hover {
  background-color: lightgray;
  font-weight: 500;
}
.menu-icon {
  display: none;
}

.responsive-menu {
  background-color: #78472b;
  position: absolute;
  width: 100%;
  z-index: 10;
  transition: all 0.6s;
  padding: 2rem 0;
}
.responsive-menu > div {
  padding: 1rem;
  color: white;
  transition: all 0.2s;
}
.responsive-menu > div:hover {
  background-color: white;
  color: black;
  cursor: pointer;
  font-weight: 500;
}
.hidden-menu {
  left: -100%;
  display: none;
}
.show-menu {
  left: 0;
}

@media screen and (max-width: 500px) {
  .menu-icon {
    display: flex;
    align-items: center;
    margin-right: 2.5rem;
  }
  .menu-icon > svg {
    font-size: 1.6rem;
    cursor: pointer;
    color: #78472b;
  }
  .header-profile,
  .add-hostel {
    display: none;
  }
  .responsive-menu {
    display: block;
  }
}
